<template>
  <div class="visit-template">
    <ListingTemplate
      :customClass="'visit-listing'"
      v-if="getPermission('visit:view')"
    >
      <template v-slot:body>
        <div class="pt-0 pb-0">
          <v-layout class="pb-2 flex-wrap justify-content-between">
            <table
              v-if="selectedRows.length"
              width="100%"
              style="table-layout: fixed"
            >
              <tr>
                <td class="pt-0" height="48px">
                  <span class="font-weight-700 font-size-20 color-custom-blue"
                    >{{ selectedRows.length }} Selected</span
                  >
                </td>
                <td class="pt-0" height="48px" align="right">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        tile
                        depressed
                        v-on:click="reassignScheduleDialog"
                        :disabled="pageLoading"
                      >
                        Bulk Assign
                      </v-btn>
                    </template>
                    <span>Bulk Assign</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2 custom-bold-button white--text"
                        color="red"
                        tile
                        v-on:click="clearSelections()"
                        depressed
                        :disabled="pageLoading"
                      >
                        Clear Selection
                      </v-btn>
                    </template>
                    <span>Clear Selection</span>
                  </v-tooltip>
                </td>
              </tr>
            </table>
            <table v-else width="100%" style="table-layout: fixed">
              <tr>
                <td width="10%" class="pt-0 pl-0">
                  <StatusFilter
                    :key="filter_key"
                    :disabled="pageLoading"
                    v-on:change="routeToFilter()"
                  />
                </td>
                <td width="15%" class="pt-0">
                  <DateRangeFilter
                    limit-month
                    :key="filter_key"
                    :page-loading="pageLoading"
                    :prop_dates="$route?.query['date-range'] ?? []"
                    v-on:change="routeToDateFilter($event)"
                  />
                </td>
                <td width="14.5%" class="pt-0">
                  <CustomerFilter
                    :key="filter_key"
                    :disabled="pageLoading"
                    v-on:change="routeToFilter()"
                  />
                </td>
                <td width="14.5%" class="pt-0">
                  <PropertyFilter
                    :key="filter_key"
                    :disabled="pageLoading"
                    v-on:change="routeToFilter()"
                  />
                </td>
                <td width="14.5%" class="pt-0">
                  <EngineerFilter
                    :key="filter_key"
                    :disabled="pageLoading"
                    v-on:change="routeToFilter()"
                  />
                </td>
                <td width="14.5%" class="pt-0">
                  <v-text-field
                    clearable
                    dense
                    filled
                    flat
                    label="Search"
                    hide-details
                    v-model="ltxFilter.search"
                    v-on:click:clear="
                      ltxFilter.search = null;
                      routeToFilter();
                    "
                    solo
                    :disabled="pageLoading"
                    color="cyan"
                  />
                </td>
                <td align="right" width="17%" class="pr-0 pt-0">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="red lighten-1"
                        tile
                        depressed
                        :disabled="!filterActive || pageLoading"
                        v-on:click="resetFilter()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset Filter</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        tile
                        depressed
                        :disabled="pageLoading"
                        v-on:click="routeToFilter()"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>Search</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        tile
                        depressed
                        :disabled="pageLoading"
                        v-on:click="createJob()"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Create a new Visit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        tile
                        depressed
                        v-on:click="importDialog = true"
                        :disabled="pageLoading"
                      >
                        <v-icon>mdi-cloud-upload-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Import Visit</span>
                  </v-tooltip>
                </td>
              </tr>
            </table>
          </v-layout>
        </div>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="8"
            :pageLoading="pageLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.visit_id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="pageLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !pageLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="pageLoading">
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="3" align="center">
                      <p class="font-size-20 font-weight-600 my-4">
                        Please wait...
                      </p>
                      <v-progress-linear
                        color="cyan"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                      <br />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
                <template v-if="rowData && rowData.length > 0">
                  <tr
                    class="visit-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                  >
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.visit_id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          !cols.checkbox
                            ? $router.push(
                                getDefaultRoute('visit.detail', {
                                  params: { id: data.visit_id },
                                  query: {
                                    'menu-active': $route.meta.menuActive,
                                  },
                                })
                              )
                            : 'javascript:void(0)'
                        "
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.visit_id"
                            :disabled="data.visit_status == 1 ? false : true"
                            :readonly="data.visit_status == 1 ? false : true"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div class="cursor-pointer">
                            <Barcode
                              dense
                              small
                              :barcode="data.visit_barcode"
                            ></Barcode>
                          </div>
                          <div class="mt-2 mb-1 cursor-pointer">
                            <Barcode
                              dense
                              small
                              :barcode="data.ticket_barcode"
                            ></Barcode>
                          </div>
                          <div class="my-0">
                            <v-chip
                              small
                              class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10"
                              color="chip-custom-blue"
                              label
                              outlined
                            >
                              {{ data.ticket_job_type_text }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'property'">
                          <div class="job-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis-two-line">
                              <b>Job Title: </b>{{ data.visit_title }}
                            </p>

                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="data.property_address"
                            >
                              <span
                                content="Property Address"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#000"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                {{ data.property_address }}
                              </span>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <div class="job-listing-customer">
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Contact Person"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-account
                                  </v-icon>
                                </b>

                                {{ data?.property_first_name }}
                                {{ data?.property_last_name }}
                              </span>
                              <!--  <v-chip
                                label
                                small
                                color="green white--text"
                                class="d-inline mx-2"
                                v-if="data.is_property_tenant"
                                >Tenant</v-chip
                              > -->
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Company Name"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-bag-checked
                                  </v-icon>
                                </b>
                                {{ data.customer_company_name }}
                              </span>
                            </p>

                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Property Contact Person Email"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-email
                                  </v-icon>
                                </b>
                                {{ data.property_primary_email }}
                              </span>
                            </p>
                            <p class="m-0">
                              <span
                                class="m-0 text-truncate"
                                content="Property Contact Person Phone"
                                v-tippy="{
                                  arrow: true,
                                  arrowType: 'round',
                                  animation: 'fade',
                                }"
                              >
                                <b>
                                  <v-icon style="font-size: 16px" color="#000">
                                    mdi-phone
                                  </v-icon>
                                </b>
                                {{ data.property_primary_phone }}1
                              </span>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'started_at'">
                          <p class="m-0 max-content-width text-truncate">
                            <span class="d-block">
                              {{ formatDate(data.visit_started_at) }}

                              {{ getTime(data.visit_started_at) }}
                              <!-- {{ formatTime(data.visit_started_at) }} -->

                              -
                              {{ getTimeEnd(data.visit_finished_at) }}
                            </span>
                            <span class="d-block">
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-blue"
                                small
                                outlined
                                label
                              >
                                <template v-if="data.visit_status === 4">
                                  completed in {{ data.visit_duration_human }}
                                </template>
                                <template v-else-if="data.visit_status === 2">
                                  in-progress
                                </template>
                                <template v-else>
                                  <template v-if="data.visit_overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(
                                        data.visit_started_at
                                      )
                                    }}</template
                                  >
                                  <template v-else-if="data.visit_today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(
                                        data.visit_finished_at
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    >starts in
                                    {{
                                      calculateTotalDuration(
                                        data.visit_started_at
                                      )
                                    }}</template
                                  >
                                </template>
                              </v-chip>
                            </span>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="mb-1">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-2 custom-status font-weight-600 custom-grey-border d-inline d-inline-table text-uppercase justify-center font-10"
                                  :color="data?.visit_status_obj?.color"
                                  :text-color="
                                    data?.visit_status_obj?.textcolor
                                  "
                                  label
                                  small
                                >
                                  {{ data?.visit_status_obj?.text }}
                                </v-chip>
                              </template>
                              <span>Status</span>
                            </v-tooltip>
                          </div>
                          <Priority
                            small
                            :priority="priortyInteger(data.ticket_priority)"
                          ></Priority>
                        </template>
                        <template v-else-if="cols.field == 'engineers'">
                          <div class="job-listing-customer ml-3">
                            <template
                              v-if="!lodash.isEmpty(data.visit_engineers)"
                            >
                              <template
                                v-for="(
                                  engineer, index
                                ) in data?.visit_engineers?.slice(0, 3)"
                              >
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                  :key="'engineer-tooltip' + index"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar
                                      v-on="on"
                                      v-bind="attrs"
                                      width="35"
                                      height="35"
                                      min-width="35"
                                      class="mr-1"
                                      color="grey lighten-2 grey--text"
                                      style="
                                        margin-left: -12px;
                                        border: 2px solid #fff !important;
                                      "
                                      :key="index"
                                    >
                                      <template
                                        v-if="engineer.profile_logo != null"
                                      >
                                        <v-img
                                          :src="engineer.profile_logo + '?fafa'"
                                        ></v-img>
                                      </template>
                                      <template v-else>
                                        <span class="fs-15">{{
                                          engineer.display_name
                                            .slice(0, 2)
                                            .toUpperCase()
                                        }}</span>
                                      </template>
                                    </v-avatar>
                                  </template>
                                  <span>{{ engineer.display_name }}</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="data?.visit_engineers?.length > 3"
                              >
                                +{{ data?.visit_engineers?.length - 3 }} more...
                              </template>
                            </template>
                            <template v-else>
                              <p
                                class="m-0 max-content-width text-truncate font-weight-700 text--secondary"
                              >
                                Not assigned yet.
                              </p>
                            </template>
                          </div>
                          <template>
                            <v-btn
                              depressed
                              color="red lighten-1 white--text visit-delete-btn my-auto mt-2"
                              v-on:click.stop="deleteVisit(data)"
                            >
                              <v-icon style="font-size: 18px; line-height: 18px"
                                >mdi-delete</v-icon
                              >
                              Delete
                            </v-btn>
                          </template>
                        </template>
                        <!--  <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template> -->
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!pageLoading">
                  <td :colspan="columnLength()" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no visit at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :pageLoading="pageLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
      </template>
    </ListingTemplate>
    <DeleteDialog :delete-dialog="deleteVisitDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteVisitDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="importDialog">
      <template v-slot:title>Import Visits</template>
      <template v-slot:body>
        <v-container fluid class="py-0">
          <table width="100%">
            <tr>
              <td width="20%">
                <label for="" class="my-0">Download Sample </label>
              </td>
              <td width="80%">
                <a :href="sampleFileUrl" target="_blank"
                  >Click here to download file</a
                >
              </td>
            </tr>
            <tr>
              <td width="20%">
                <label for="" class="my-0">Excel File</label>
              </td>
              <td width="80%">
                <v-file-input
                  dense
                  filled
                  solo
                  flat
                  prepend-icon=""
                  prepend-inner-icon="mdi-attachment"
                  v-model="import_file"
                  color="cyan"
                  placeholder="Select a valid excel file."
                ></v-file-input>
              </td>
            </tr>
            <tr>
              <td width="20%">
                <label for="" class="my-0">Email</label>
              </td>
              <td width="80%">
                <v-text-field
                  id="import-email"
                  v-model.trim="import_email"
                  :rules="[validateRules.validEmail(import_email, 'email')]"
                  dense
                  filled
                  label="Email"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p class="my-0 text--red font-weight-500">
                  Note: Once import is completed, then system will send a
                  notification on above email.
                </p>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="submitFile()"
          :disabled="import_loading"
          :loading="import_loading"
        >
          Import
        </v-btn>
        <v-btn
          :disabled="import_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="importDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <ReassignSchedule
      v-if="reassign_dialog"
      :key="`re-assign-schedule-${reassign_schedule_key}`"
      :reassign-schedule-dialog="reassign_dialog"
      v-on:close="reassign_dialog = false"
      :visit-arr="selectedRows"
      v-on:success="getVisits()"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { QUERY, DELETE, UPLOAD } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { getConfig } from "@/core/services/local.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ReassignSchedule from "@/view/pages/partials/Reassign-Schedule.vue";
import Priority from "@/view/pages/partials/Priority.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SearchEventBus } from "@/core/lib/search.lib";

import Dialog from "@/view/pages/partials/Dialog";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  SET_PRE_FILTER,
  GET_FILTER,
  CLEAR_FILTER,
} from "@/core/services/store/filter.module";
import { SET_VISIT_TIMEOUT } from "@/core/services/store/visit.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import EngineerFilter from "@/view/pages/partials/Filter/Engineer-Filter.vue";
import CustomerFilter from "@/view/pages/partials/Filter/Customer-Filter.vue";
import PropertyFilter from "@/view/pages/partials/Filter/Property-Filter.vue";
import StatusFilter from "@/view/pages/partials/Filter/Visit-Status-Filter.vue";
import DateRangeFilter from "@/view/pages/partials/DateRangePicker.vue";
import { mapGetters } from "vuex";
//import SamePostalCode from "@/view/pages/partials/Same-Postal-Code.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-listing-template",
  mixins: [CommonMixin, ValidationMixin, ListingMixin],
  props: {
    visitType: {
      type: String,
      required: true,
      default: "all",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    entityId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    entityId() {
      this.initInternal();
    },
    customerId() {
      this.initInternal();
    },
    engineerId() {
      this.initInternal();
    },
    quotationId() {
      this.initInternal();
    },
    invoiceId() {
      this.initInternal();
    },
  },
  components: {
    Dialog,
    DeleteDialog,
    Barcode,
    Priority,
    ListingFooter,
    ReassignSchedule,
    ListingTemplate,
    EngineerFilter,
    CustomerFilter,
    PropertyFilter,
    StatusFilter,
    DateRangeFilter,
    ListingTable,
    // SamePostalCode,
  },
  data() {
    return {
      // managePostalCodeDialog: false,
      filter_key: Number(new Date()),
      reassign_schedule_key: Number(new Date()),
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      sampleFileUrl: null,
      import_email: null,
      import_file: null,
      import_loading: false,
      importDialog: false,
      pageLoading: false,
      reassign_dialog: false,
      customerLoading: false,
      propertyLoading: false,
      customerDialog: false,
      entityDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      engineerLoading: false,
      isRowLoading: {},
      dates: [],
      statusList: [],
      customerList: [],
      propertyList: [],
      visitArr: [],
      engineerList: [],
      rowData: [],
      filter_search: null,
      filter_customer: null,
      filter_engineer: null,
      filter_quotation: null,
      filter_invoice: null,
      filter_status: null,
      filter_property: null,
      timeout: null,
      pageModule: "visit-listing",
      timeoutLimit: 500,
      entity: 0,
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      entityList: [],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
      ],
      entityObject: {},
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_PRE_FILTER);

    _this.$store.dispatch(GET_FILTER);

    SearchEventBus.$emit("search-template", false);

    _this.sampleFileUrl = _this.$assetURL(
      "sample/xterminate-import-visit-sample.xlsx"
    );

    _this.initInternal();

    _this.entityList = getConfig("entity");

    _this.entityObject = _this.lodash.head(_this.entityList);

    _this.$nextTick(() => {
      if (!_this.internal || _this.engineerId) {
        _this.getVisits();
      }
    });
  },
  methods: {
    routeToDateFilter(date_range) {
      const paramQuery = new Object();

      paramQuery.t = Number(new Date());

      if (date_range && date_range?.length == 2) {
        paramQuery["date-range"] = date_range;

        this.$router
          .push({
            name: this.$route.name,
            query: { ...this.$route.query, ...paramQuery },
          })
          .then(() => {
            this.getVisits();
          });
      }
    },
    resetFilter() {
      const paramQuery = new Object();

      paramQuery.t = Number(new Date());
      paramQuery.status = "all";
      paramQuery.tab = this.$route?.query?.tab ?? "all";
      paramQuery.visit_status = 0;
      paramQuery.page = 1;

      this.$router
        .push({ name: this.$route.name, query: { ...paramQuery } })
        .then(() => {
          this.filter_key = Number(new Date());
          this.$store.dispatch(CLEAR_FILTER);
          this.getVisits();
        });
    },
    routeToFilter() {
      const paramQuery = new Object();

      const { search, date_range, customer, property, engineer, visit_status } =
        this.ltxFilter;

      if (search) {
        paramQuery.search = search;
      }

      if (customer && customer?.id) {
        paramQuery.customer = customer?.id;
      }

      if (property && property?.id) {
        paramQuery.property = property?.id;
      }

      if (engineer && engineer?.id) {
        paramQuery.engineer = engineer?.id;
      }

      if (visit_status && visit_status?.status > -1) {
        paramQuery.visit_status = visit_status.status;
      }

      if (date_range && date_range?.length == 2) {
        paramQuery["date-range"] = date_range;
      }

      if (this.currentPage) {
        paramQuery["page"] = this.currentPage;
      }

      paramQuery.t = Number(new Date());

      this.$router
        .push({
          name: this.$route.name,
          query: { ...this.$route.query, ...paramQuery },
        })
        .then(() => {
          this.getVisits();
        });
    },
    getTime(date) {
      return moment(date).format("hh:mm A");
    },
    getTimeEnd(date) {
      return moment(date).format("hh:mm A");
    },
    reassignScheduleDialog() {
      if (
        this.selectedRows &&
        this.selectedRows.length &&
        this.selectedRows.length == 1
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select more than one visit")
        );
        return false;
      }
      this.reassign_schedule_key = Number(new Date());
      this.$nextTick(() => {
        this.reassign_dialog = true;
      });
    },

    downloadFile() {
      let file_url = null;
      console.log(file_url);
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.import_file);
      formData.append("import_email", this.import_email);
      this.import_loading = true;
      this.$store
        .dispatch(UPLOAD, { url: `visit/import`, data: formData })
        .then(() => {
          this.importDialog = false;
          this.import_file = null;
          this.import_email = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.import_loading = false;
        });
    },
    deleteVisit({ visit_id, visit_barcode }) {
      this.deleteVisitId = visit_id;
      this.deleteVisitDialog = true;
      this.deleteVisitBarcode = visit_barcode;
    },
    deleteVisitConfirm() {
      this.deleteVisitLoading = true;
      this.$store
        .dispatch(DELETE, { url: `visit/${this.deleteVisitId}` })
        .then(() => {
          this.deleteVisitId = null;
          this.deleteVisitDialog = false;
          this.deleteVisitBarcode = null;
          this.getVisits();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },
    createJob() {
      this.$router.push(this.getDefaultRoute("job.create"));

      /* if (!this.entity) {
        this.entityDialog = true;
      }  if (!this.customer) {
        this.customerDialog = true;
      } else if (!this.customerProperty) {
        this.customerPropertyDialog = true;
      }*/
    },
    initInternal() {
      this.entity = this.lodash.toSafeInteger(this.entityId);
      this.customer = this.lodash.toSafeInteger(this.customerId);
      this.filter_quotation = this.lodash.toSafeInteger(this.quotationId);
      this.filter_invoice = this.lodash.toSafeInteger(this.invoiceId);
      this.filter_engineer = this.lodash.toSafeInteger(this.engineerId);
      this.filter_customer = this.customer;
      if (this.filter_engineer) {
        this.$nextTick(() => {
          this.getVisits();
        });
      }
      if (this.entity && this.customer && this.filter_customer) {
        this.$nextTick(() => {
          this.getVisits();
        });
      }
    },
    updateEntity(row) {
      this.entityObject = row;
      this.getVisits();
      this.propertyList = [];
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 0;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectProject(param) {
      this.projectId = param;
    },
    selectContact(param) {
      this.contractId = param;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("job.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisits();
    },
    getVisits() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      _this.clearSelections();

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      clearTimeout(this.getVisitListingTimeout);

      _this.pageLoading = true;
      _this.$emit("update-loading", _this.pageLoading);

      const timeout = setTimeout(() => {
        _this
          .getVisitList()
          .then((data) => {
            _this.rowData = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
            _this.$emit("update-loading", _this.pageLoading);
          });
      }, 500);

      this.$store.commit(SET_VISIT_TIMEOUT, timeout);
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    getVisitList() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          const filter = {
            ...this.$route.query,
            type: _this.visitType,
            internal: _this.internal ? 1 : 0,
          };

          if (_this.customerId) {
            filter.customer = _this.customerId;
          }

          if (_this.engineerId) {
            filter.engineer = _this.engineerId;
          }

          if (_this.entityId) {
            filter.entity = _this.entityId;
          }

          _this.$store
            .dispatch(QUERY, { url: "visit-v1", data: { ...filter } })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    statusIntiger(param) {
      return this.lodash.toSafeInteger(param);
    },
    priortyInteger(param) {
      return this.lodash.toSafeInteger(param);
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row).format("hh:mm A");
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
  },
  computed: {
    ...mapGetters(["ltxFilter", "getVisitListingTimeout"]),
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
    filterActive() {
      const query = this.$route.query;
      const dtype = ["string", "number"];

      if (
        dtype.includes(typeof query.visit_status) &&
        Number(query.visit_status) > 0
      ) {
        return true;
      }

      if (dtype.includes(typeof query.customer) && Number(query.customer) > 0) {
        return true;
      }

      if (dtype.includes(typeof query.property) && Number(query.property) > 0) {
        return true;
      }

      if (dtype.includes(typeof query.engineer) && Number(query.engineer) > 0) {
        return true;
      }

      if (typeof query.search == "string" && query.search.length > 0) {
        return true;
      }

      if (
        typeof query["date-range"] == "object" &&
        query["date-range"].length == 2
      ) {
        return true;
      }

      return false;
    },
  },
  beforeDestroy() {
    this.$store.dispatch(CLEAR_FILTER);
    /*PaginationEventBus.$off("update:pagination");
    PaginationEventBus.$off("update:per-page");*/
  },
  beforeMount() {
    const _this = this;

    const paramQuery = _this.$route.query;

    _this.lodash.assign(_this.queryParams, paramQuery);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Visit #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Property",
        field: "property",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "200px",
      },
      {
        headerName: "Customer Details",
        field: "customer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "started_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "300px",
      },
      {
        headerName: "Assign To",
        field: "engineers",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
        width: "300px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  created() {
    const _this = this;

    const statusText = _this.lodash.toLower(_this.$route.query.status) || "all";

    if (statusText) {
      switch (statusText) {
        case "all":
          _this.filter_status = 0;
          break;
        case "open":
          _this.filter_status = 1;
          break;
        case "cancel":
          _this.filter_status = 5;
          break;
      }
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.routeToFilter();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>

<template>
  <v-menu
    v-model="ltxFilterDropDown.person"
    content-class="bg-white"
    left
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        flat
        label="Select Contact Person"
        hide-details
        solo
        readonly
        :disabled="disabled"
        color="cyan"
        v-bind="attrs"
        v-on="on"
        append-icon="$dropdown"
        :value="
          ltxFilter?.person?.id
            ? `${ltxFilter?.person?.barcode} - ${ltxFilter?.person?.display_name}`
            : ''
        "
        :class="{ 'menu-open': ltxFilterDropDown.person }"
        :loading="ltxFilterLoading.person"
      />
    </template>
    <v-list height="500px" class="py-0" color="cyan--text" dense>
      <v-list-item
        class="custom-border-bottom bg-white"
        style="position: sticky; top: 0; z-index: 9999"
      >
        <v-list-item-content>
          <div class="d-flex">
            <v-text-field
              clearable
              dense
              filled
              flat
              class="pl-0"
              label="Search Contact Person..."
              hide-details
              solo
              v-model="filterSearch"
              color="cyan"
              v-on:keyup.enter="filterPerson()"
              :disabled="ltxFilterLoading.person"
              :loading="ltxFilterLoading.person"
            />
            <v-btn
              v-on:click="filterPerson()"
              :disabled="ltxFilterLoading.person"
              style="min-height: 32px"
              depressed
              color="cyan white--text"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="ltxFilterLoading.person" dense class="filter-loader">
        <v-skeleton-loader size="40" class="px-0" type="list-item-avatar" />
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <v-skeleton-loader type="chip" />
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <v-skeleton-loader type="text" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        dense
        selectable
        v-for="(item, index) in ltxFilterPersonList"
        :input-value="ltxFilter?.person?.id == item.id"
        :key="`person-filter-${index}`"
        v-on:click="onClickEvent(item)"
      >
        <v-list-item-avatar
          height="40"
          min-width="40"
          width="40"
          class="mr-2 my-2 grey lighten-2 grey--text"
        >
          <v-img v-if="item?.profile_logo" :src="item.profile_logo" />
          <span v-else class="fw-600 mb-1 font-size-20">{{
            item?.display_name?.slice(0, 2).toUpperCase()
          }}</span>
        </v-list-item-avatar>
        <v-list-item-content class="text-ellipsis max-width-400px">
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            <span class="filter-list-barcode">{{ item?.barcode }}</span>
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            {{ item?.display_name }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            {{ item?.primary_email }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-capitalize font-weight-500 font-size-16"
            style="line-height: unset"
          >
            {{ item?.primary_phone }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action
          class="align-self-center my-0"
          v-if="ltxFilter?.customer?.id == item.id"
        >
          <v-icon color="cyan">mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_STATE, GET_PERSON } from "@/core/services/store/filter.module";

export default {
  name: "person-filter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterSearch: null,
      filterTimeout: null,
      filterTimeoutLimit: 0,
    };
  },
  watch: {
    "ltxFilterDropDown.person"(param) {
      if (!param && this.filterSearch) {
        this.filterSearch = null;
        this.filterPerson();
      }
    },
  },
  methods: {
    onClickEvent(item) {
      if (!item?.id || item?.id == "UNDEFINED") {
        return false;
      }

      this.setFilterState("db_filter_dropdown.person", false);
      this.setFilterState("db_filter.person", item);

      this.$emit("change", item);
    },
    filterPerson() {
      this.setFilterState("db_filter_person", []);
      this.$store.dispatch(GET_PERSON, { search: this.filterSearch });
    },
    setFilterState(key, value) {
      this.$store.commit(SET_STATE, { key, value });
    },
  },
  computed: {
    ...mapGetters([
      "ltxFilter",
      "ltxFilterDropDown",
      "ltxFilterPersonList",
      "ltxFilterLoading",
    ]),
  },
};
</script>

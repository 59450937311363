<template>
  <v-autocomplete
    dense
    color="cyan"
    filled
    :id="id"
    :items="timeList"
    v-model="time"
    :placeholder="placeholder"
    solo
    flat
    item-color="cyan"
    hide-details
    v-on:change="emitValue()"
    :disabled="disabled"
  />
</template>

<script>
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "timepicker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    id: {
      type: String,
      default: "start-time",
    },
    placeholder: {
      type: String,
      default: "Start Time",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    minTime: {
      type: String,
      default: null,
    },
  },
  watch: {
    value: function () {
      this.init();
    },
    minTime: function () {
      /*  this.time = null; */
      this.emitValue();
      this.initTimeList();
    },
  },
  data() {
    return {
      time: null,
      timeList: [],
    };
  },
  methods: {
    init() {
      this.time = this.value;
    },
    emitValue() {
      this.$emit("input", this.time);
      this.$emit("change", this.time);
    },
    initTimeList() {
      this.timeList = [];
      let startTime = moment().startOf("day");
      let endTime = moment().endOf("day");
      let minTime = this.minTime
        ? moment(`${moment().format("YYYY-MM-DD")} ${this.minTime}`)
        : null;
      let value = startTime.format("hh:mm A");
      while (startTime.isBefore(endTime)) {
        if (startTime && startTime.isBefore(minTime)) {
          this.timeList.push({ text: value, value, disabled: true });
        } else {
          this.timeList.push({ text: value, value, disabled: false });
        }
        value = startTime.add(30, "minutes").format("hh:mm A");
      }
    },
    /* initTimeList() {
        this.timeList = [];
        let startTime = moment().startOf("day");
        let endTime = moment().endOf("day");
        let minTime = this.minTime
          ? moment(`${moment().format("YYYY-MM-DD")} ${this.minTime}`)
          : null;
        while (startTime.isBefore(endTime)) {
  
          let value = startTime.add(30, "minutes").format("hh:mm A");
          if (startTime && startTime.isBefore(minTime)) {
            this.timeList.push({ text: value, value, disabled: true });
          } else {
            this.timeList.push({ text: value, value, disabled: false });
          }
        }
      }, */
  },
  mounted() {
    this.init();
    this.initTimeList();
  },
};
</script>
